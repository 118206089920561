import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Thumbnail from "../components/thumbnail"
import Showreel from "../components/showreel"

const Container = styled.div`
  @media only screen and (max-width: 760px) {
    padding-top: 150px;
    background: #000;
  }
`

const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #000000;
`

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      allProjectsJson {
        edges {
          node {
            name
            scope
            client
            slug
            type
            year
            location
            invertedThumb
            thumbnails {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const [filter, setFilter] = useState(null)

  const updateFilter = value => {
    setFilter(value)
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    if (props.location.state && props.location.state.fromContacts) {
      updateFilter(props.location.state.target)
    }
  }, [])

  return (
    <Layout showHeader {...props} onFilter={updateFilter} mainPage={true}>
      <SEO title="Main" />
      <Container>
        <Showreel />
      </Container>
      <Thumbnails>
        {data.allProjectsJson.edges.map(p => (
          <>
            {filter ? (
              p.node.type.includes(filter) && (
                <Thumbnail info={p.node} key={p.node.slug} />
              )
            ) : (
              <Thumbnail info={p.node} key={p.node.slug} />
            )}
          </>
        ))}
      </Thumbnails>
    </Layout>
  )
}

export default IndexPage
