import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "./slider"

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #000000;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`

export default function Showreel() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { name: { regex: "^/showreel_[0-9]/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges.map(i => {
    return { url: i.node }
  })

  return (
    <Wrapper>
      <Slider images={images} autoplay={true} />
      {/* {console.log(">>>", images)} */}
    </Wrapper>
  )
}
