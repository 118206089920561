import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { motion } from "framer-motion"

const Description = styled.div`
  padding: 22px 18px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  opacity: 0;
  @media only screen and (max-width: 760px) {
    font-size: 11px;
    grid-template-columns: 1fr 1fr;
  }
`

const StyledThumbnail = styled(Link)`
  max-width: 50%;
  position: relative;
  display: flex;
  flex: 1 0 50%;
  font-family: "Work Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  color: ${props => (props.inverted ? "#ffffff" : "#000000")};
  background-color: #000000;
  background-size: cover;
  text-decoration: none;
  /* background-color: ${props => (props.isloading ? "blue" : "red")}; */
  transition: all 0.4s ease;
  &:hover ${Description} {
    opacity: 1;
  }
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  @media only screen and (max-width: 760px) {
    flex: 1 0 100%;
    background-size: cover;
    max-width: 100%;
    /* &:after {
    content: "";
    display: block;
    padding-bottom: auto;
  } */
  }
`

const ThumbnailInner = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
`

const MotionDiv = styled(motion.div)`
  width: 100%;
  height: 100%;
`

const Column = styled.div`
  padding-right: 2em;
`

export default function Thumbnail({ info }) {
  const [hover, setHover] = useState(false)

  const hoverHandler = () => {
    setHover(true)
  }

  const renderMultiPicThumbnail = () => {
    return (
      <>
        {!hover ? (
          <ThumbnailInner
            fluid={info.thumbnails[0].childImageSharp.fluid}
            loading="eager"
            critical
          ></ThumbnailInner>
        ) : (
          <MotionDiv initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <ThumbnailInner
              fluid={info.thumbnails[1].childImageSharp.fluid}
              loading="eager"
              critical
            >
              <Description>
                <Column>
                  PROJECT: {info.name}
                  <br />
                  SCOPE OF WORK: {info.scope}
                </Column>
                <Column>
                  {info.client ? (
                    <>
                      CLIENT: {info.client}
                      <br />
                    </>
                  ) : null}
                  YEAR: {info.year}
                </Column>
                <Column>LOCATION: {info.location}</Column>
              </Description>
            </ThumbnailInner>
          </MotionDiv>
        )}
      </>
    )
  }

  const renderSinglePicThumbnail = () => {
    return (
      <>
        <MotionDiv initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <ThumbnailInner
            fluid={info.thumbnails[0].childImageSharp.fluid}
            loading="eager"
            critical
          >
            <Description>
              <Column>
                PROJECT: {info.name}
                <br />
                SCOPE OF WORK: {info.scope}
              </Column>
              <Column>
                {info.client ? (
                  <>
                    CLIENT: {info.client}
                    <br />
                  </>
                ) : null}
                YEAR: {info.year}
              </Column>
              <Column>LOCATION: {info.location}</Column>
            </Description>
          </ThumbnailInner>
        </MotionDiv>
      </>
    )
  }

  return (
    <StyledThumbnail
      to={`/project/${info.slug}`}
      onMouseOver={hoverHandler}
      onMouseLeave={() => setHover(false)}
      inverted={info.invertedThumb}
    >
      {info.thumbnails.length !== 1
        ? renderMultiPicThumbnail()
        : renderSinglePicThumbnail()}
    </StyledThumbnail>
  )
}
